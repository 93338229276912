/* admin testfunction */
export const GET_TEST_DATA = '/admin/testfunction';

/* SERVICE SERVER test */
export const GET_TEST2_DATA = '/testfeed/getdata';
export const GET_TESTJWT_DATA = '/testing/get_jwt_data';


export const MQTT_TOPIC_DATA      = '/mqtt/topics';
export const MQTT_TOPIC_SETSTATE  = '/mqtt/topics/setState';
export const MQTT_TOPIC_CLEARMSG  = '/mqtt/topics/clearMessages';
export const MQTT_TOPIC_SETHIDE   = '/mqtt/topics/setHide';


export const MQTT_GIVE_FOOD       = '/mqtt/givefood';
export const MQTT_GIVE_FOOD2      = '/mqtt/givefood2';

export const MQTT_MALTA_NUC_OPENFIREWALL      = '/mqtt/malta/nuc/openfirewall';
export const MQTT_MALTA_NUC_CLOSEFIREWALL     = '/mqtt/malta/nuc/closefirewall';
export const MQTT_MALTA_NUC_REBOOT            = '/mqtt/malta/nuc/reboot';

export const MQTT_MALTA_MYMEDIAPC_START       = '/mqtt/malta/mymediapc/start';
export const MQTT_MALTA_MYMEDIAPC_SHUTDOWN    = '/mqtt/malta/mymediapc/shutdown';
export const MQTT_MALTA_MYMEDIAPC_REBOOT      = '/mqtt/malta/mymediapc/reboot';
export const MQTT_MALTA_MYMEDIAPC_CURRENT     = '/mqtt/malta/mymediapc/current';


export const MQTT_MALTA_MALTAPC_START       = '/mqtt/malta/maltapc/start';
export const MQTT_MALTA_MALTAPC_SHUTDOWN    = '/mqtt/malta/maltapc/shutdown';
export const MQTT_MALTA_MALTAPC_REBOOT      = '/mqtt/malta/maltapc/reboot';
export const MQTT_MALTA_MALTAPC_CURRENT     = '/mqtt/malta/maltapc/current';


export const CRYPTO_PRICE_DATA     = '/crypto/getprices';
export const CRYPTO_PRICE_CHECKED     = '/crypto/setChecked';







