import todopage           from "@/views/public/general/todopage";
import Login              from "@/views/public/general/login";
import PasswordReset      from "@/views/public/general/passwordreset";
import VerifyEmail        from "@/views/public/general/verifyemail";
import Contact            from "@/views/public/general/contact";
import Privacy            from "@/views/public/general/privacy";
import TermsAndConditions from "@/views/public/general/termsandconditions";
import Disclaimer         from "@/views/public/general/disclaimer";
import Home               from "@/views/public/home";
import Test               from "@/views/public/test/test";

import GridExample        from "@/views/public/test/grid-example";
import FlexBoxExample     from "@/views/public/test/flexbox-example";
import FlexBoxTest        from "@/views/public/test/flexbox-test";
import GridExample2       from "@/views/public/test/grid-example2";


 const Routes_Public = [{
                              path: "/",
                              name: "home",
                              component: Home,
                              meta: { navBar: true }
                            },{
                              path: "/GridExample",
                              name: "GridExample",
                              component: GridExample,
                              props: true,
                              meta: { navBar: false, hideFooter : true }
                            },{
                              path: "/FlexBoxExample",
                              name: "FlexBoxExample",
                              component: FlexBoxExample,
                              props: true,
                              meta: { navBar: false, hideFooter : true }
                            },{
                              path: "/FlexBoxTest",
                              name: "FlexBoxTest",
                              component: FlexBoxTest,
                              props: true,
                              meta: { navBar: true, hideFooter : true }
                            },{
                              path: "/GridExample2",
                              name: "GridExample2",
                              component: GridExample2,
                              props: true,
                              meta: { navBar: false, hideFooter : true }
                            },{
                              path: "/login",
                              name: "Login",
                              component: Login,
                              props: true,
                              meta: { navBar: false, hideFooter : true }
                            },{
                              path: "/register",
                              name: "register",
                              component: Login,
                              props: { pageViewMode: 'register' },
                              meta: { navBar: false, hideFooter : true }
                            },{
                              path: "/.well-known/change-password",
                              name: "change-password",
                              component: Login,
                              props: { pageViewMode: 'pw_reset' },
                              meta: { navBar: false, hideFooter : true }
                            },{
                              path: "/password-reset",
                              name: "passwordreset",
                              component: PasswordReset,
                              props: true,
                              meta: { navBar: false, hideFooter : true }
                            },{
                              path: "/verifyemail",
                              name: "verifyemail",
                              component: VerifyEmail,
                              props: true,
                              meta: { navBar: false, hideFooter : true }
                            },{
                              path: "/contact",
                              name: "Contact",
                              component: Contact,
                              props: true,
                              meta: { navBar: true }
                            },{
                              path: "/privacy",
                              name: "Privacy",
                              component: Privacy,
                              props: true,
                              meta: { navBar: true }
                            },{
                              path: "/termsandconditions",
                              name: "TermsAndConditions",
                              component: TermsAndConditions,
                              props: true,
                              meta: { navBar: true }
                            },{
                              path: "/disclaimer",
                              name: "Disclaimer",
                              component: Disclaimer,
                              props: true,
                              meta: { navBar: true }
                            },{
                              path: "/test",
                              name: "Test",
                              component: Test,
                              props: true,
                              meta: { navBar: true }
                              
                              
                              
                            },{
                              path: "/home",
                              name: "Home",
                              component: todopage,
                              props: true,
                              meta: { navBar: true }
                            },{
                              path: "/app",
                              name: "GetApp",
                              component: todopage,
                              props: true,
                              meta: { navBar: true }
                            },{
                              path: "/aboutus",
                              name: "Aboutus",
                              component: todopage,
                              props: true,
                              meta: { navBar: true }
                            
                            }
];

export default Routes_Public;