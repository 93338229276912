<template>
  <section class="wa-full-height">

    <div class="columns is-centered is-gapless is-marginless">
      <div class="column is-mobile is-narrow mt2rem mb2rem">
        <div class="has-text-grey is-size-2 is-size-4-mobile wa-underlined-title">Overview</div>
      </div>
    </div>
    
    <!-- <div v-if="cryptoData === null" class="columns is-centered is-gapless is-marginless is-multiline">
      loading...
    </div> -->
    
    <div v-if="cryptoData !== null" class="columns is-centered is-gapless is-marginless is-multiline">
      
      <div class="column is-12-desktop is-12-tablet is-12-mobile mb1rem">
        <b-button class="button is-primary" @click="updatePriceData">
          updatePriceData
        </b-button>
      </div>
      
      <div class="column is-12-desktop is-12-tablet is-12-mobile mb1rem">
        <h3>
          <span class="left">BSV:</span> {{ formatValue(cryptoData.bsvEuro) }} 
          <span class="diff" :class='getPriceDiffClass(cryptoData.lastCheckedPriceBSV, cryptoData.bsvEuro)'>
              {{ getPriceDiffText(cryptoData.lastCheckedPriceBSV, cryptoData.bsvEuro, 2) }}
          </span>
        </h3>
      </div>
      
      <div class="column is-12-desktop is-12-tablet is-12-mobile mb1rem">
        <h3>
          <span class="left">BTC:</span> {{ formatValue(cryptoData.btcEuro, 0) }}
          <span class="diff" :class='getPriceDiffClass(cryptoData.lastCheckedPriceBTC, cryptoData.btcEuro)'>
              {{ getPriceDiffText(cryptoData.lastCheckedPriceBTC, cryptoData.btcEuro, 2) }}
          </span>
        </h3>
      </div>
      
      <div class="column is-12-desktop is-12-tablet is-12-mobile mb1rem">
        <h3>
          <span class="left">Dollar:</span> {{ formatValue(1/ cryptoData.euroDollar) }}
          <span class="diff" :class='getPriceDiffClass(cryptoData.lastCheckedPriceEuroDollar, cryptoData.euroDollar)'>
            {{ getPriceDiffText(cryptoData.lastCheckedPriceEuroDollar, cryptoData.euroDollar, 2) }}
          </span>
        </h3>
      </div>
      
      <div class="column is-12-desktop is-12-tablet is-12-mobile mb1rem">
        <h3>
          <span class="left">Total:</span> {{ formatValue(1.104 * cryptoData.bsvEuro, 0) }}
        </h3>
      </div>
      
      <div class="column is-12-desktop is-12-tablet is-12-mobile mb1rem">
        <b-button class="button is-primary" @click="setChecked">
          setChecked
        </b-button>
      </div>
      
      
    </div>
    
    
    <b-loading :is-full-page="true" :active.sync="isLoading"></b-loading>
    
  </section>
</template>

<script>

// import Card from "@/components/public/general/card.vue";
// import ButtonsForm from "@/components/public/general/buttonform.vue";
// import { DialogProgrammatic as Dialog } from 'buefy'

import axios_api from '@/plugins/axios_api';
import { 
  CRYPTO_PRICE_DATA,
  CRYPTO_PRICE_CHECKED,
  
} from '@/config/api-url-custom';



export default {
  name: "crypto",  
  components: {
    // Card,
    // ButtonsForm,
  },
    
  data() {
    return {
      // mqttdata: [],
      isLoading: true,
      cryptoData: null
    }
  },
  
  created() {
    // const curDate = new Date()
  },
  
  mounted(){
    this.getPriceData()  
  },
  
  methods: {
    
    formatValue(val, dec = 2) {
      // console.info('formatValue:', {val, dec})
      return new Intl.NumberFormat('en-EN', { 
        style: 'currency', 
        currency: 'EUR', 
        maximumFractionDigits: dec 
      }).format(val)
    },
    
    
    getPriceDiff(initPrice, newPrice){
      // console.info(`getPriceDiff:`, {initPrice, newPrice, dec})
      if (!initPrice || !newPrice) return ''
      if (Math.abs(initPrice) <= 0.01) return 0
      if (Math.abs(newPrice) <= 0.01) return 0
      
      let diff = 1 - (initPrice / newPrice)
      // console.info(`diff:`, diff)
      // if (diff < 0.1) return
      
      return diff
    },
    
    getPriceDiffText(initPrice, newPrice){
      const diff = this.getPriceDiff(initPrice, newPrice)
      // console.info(`getPriceDiffText:`, diff)
      if (Math.abs(diff) < 0.01) return ''      
      
      return ` (${Math.round(diff * 1000) / 10}%)`
    },
    getPriceDiffClass(initPrice, newPrice){
      const diff = this.getPriceDiff(initPrice, newPrice) 
      // console.info(`getPriceDiffClass:`, diff)
      if (Math.abs(diff) < 0.01) return 'equalPrice'
      if (diff < 0) return 'lowerPrice'
      if (diff > 0) return 'higherPrice'
    },
    
    async updatePriceData() {
      this.getPriceData()
    },
    
    async setChecked() {
      this.isLoading = true;
      await axios_api.post(`${CRYPTO_PRICE_CHECKED}`, {
        euroDollar: this.cryptoData.euroDollar,
        btcPrice: this.cryptoData.btcEuro,
        bsvPrice: this.cryptoData.bsvEuro
      });
      this.isLoading = false;
      this.getPriceData()
    },
    
    async getPriceData(){
      // console.info(`getPriceData`);
      this.isLoading = true;
      let rtn = await axios_api.get(`${CRYPTO_PRICE_DATA}`);
      // console.info(`rtn:`, rtn.data.payload);
      this.cryptoData = rtn.data.payload
      // console.info(`this.cryptoData:`, this.cryptoData)
      this.isLoading = false;
    },
    
  }
  
};
</script>

<style scoped>
  

  .modalcontainer{
    background-color: #FFF;
    padding: 1em;
    text-align: center;
  }
  
  .left{
    float:left;
    font-weight: bold;
    width: 25%;
    margin-left: 2%;
    /* background-color: red; */
  }
  .diff {
    font-size: 0.8em;
  }
  
  .equalPrice{
    color: rgb(74, 74, 74)
  }
  .lowerPrice{
    color: red
  }
  .higherPrice{
    color: green
  }
  
</style>