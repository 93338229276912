import Vue from "vue";
import App from "@/App.vue";

import router from "@/router/router";
import store from "@/store";
store.dispatch('notifications/updatePermission');

import { 
    Navbar, Button, Field, Input, Icon, Loading, Checkbox, Select, Dialog, Snackbar, Toast, Tooltip, 
    Table, Dropdown, Switch, Modal, Timepicker
} from "buefy";
Vue.use(Navbar);
Vue.use(Button);
Vue.use(Field);
Vue.use(Input);
Vue.use(Icon);
Vue.use(Loading);
Vue.use(Checkbox);
Vue.use(Select);
Vue.use(Dialog);
Vue.use(Snackbar);
Vue.use(Toast);
Vue.use(Tooltip);
Vue.use(Table);
Vue.use(Dropdown);
Vue.use(Switch);
Vue.use(Modal);
Vue.use(Timepicker);
// import Buefy from "buefy";
// Vue.use(Buefy);
require("@/assets/css/main.scss");


import { messaging }                          from '@/plugins/firebase/messaging';
import { onMessage }                          from "firebase/messaging";
 

onMessage(messaging, (payload) => {
  console.info('Receiving foreground message', payload.data);
  window.alert(payload.notification.body);
});
Vue.prototype.$messaging = messaging;


import web_lang from '@/lang/web_language'
Vue.prototype.$wl = web_lang;

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
