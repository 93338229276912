
module.exports = {
  Array_removeDuplicates(ToCheckArray, CaseSensitive = true){
    let RtnArray = [];
    let TmpObj = {};
    for(let val of ToCheckArray) {
      let checkVal = val
      if (CaseSensitive !== true) {
        checkVal = val.toLowerCase(); 
      }
      if (typeof(TmpObj[checkVal]) === "undefined") {
        TmpObj[checkVal] = true;
        RtnArray.push(val);
      }
    }
    
    return RtnArray;
  },
  
  
  
  isArray(arr) {
    return arr.constructor == Array;
  },
  
  isObject(obj){
    return obj.constructor == Object;
  },
  
  arrayToObject(arr, ObjVal = true) {
    const rtnObj = {};
    for(let item of arr) {
      rtnObj[item.toString()] = ObjVal;
    }
    return rtnObj;
  },
  
  ObjItemExists(obj, Itemkey) {
    if (obj.constructor !== Object) return false;
    if (typeof(obj[Itemkey]) === "undefined") return false
    
    return true;
  },
  
  getArrayKeys(ThaArray) {
    let KeyArr = [];
    for(let key in ThaArray) {
      KeyArr.push(key);
    }
    return KeyArr;
  },
  
  toConsoleTableArray(ThaArray, colomsKeysArr = null) {
    if (colomsKeysArr) {
      return console.table(ThaArray, colomsKeysArr);
    } 
    
    return console.table(ThaArray);
  },
  
  
  JSON_Decode(ThaValue, failValue = null) {
    try {
      if (typeof(ThaValue) !== "string" || ThaValue.length<=0) {
        return failValue;
      }
      
      return JSON.parse(ThaValue);
      
    } catch (err) {
      // console.info("Error JSON_Decode:", err);
      return failValue;
    }
  },
  
  PrintJSON(elm, path = '', depth = 0){
    try {
      let depthSpaces = " ".repeat(depth);
      if (typeof(elm) === "number" || typeof(elm) === "string") {
        return console.info(`${depthSpaces}${path}:`, elm)
      }
      if (elm === null) {
        return console.info(`${depthSpaces}${path}:`, `NULL`)
      }
      if (elm === true) {
        return console.info(`${depthSpaces}${path}:`, `TRUE`)
      }
      if (elm === false) {
        return console.info(`${depthSpaces}${path}:`, `FALSE`)
      }
      if (elm === false) {
        return console.info(`${depthSpaces}${path}:`, `FALSE`);
      }
      
      if (elm.constructor == Array) {
        return console.info(`${depthSpaces}${path}:`, `[${elm.join(', ')}]`);
      }
      
      if (elm.constructor === Object) {
        for(let key in elm) {
          // console.info(`-->`, key);
          module.exports.PrintJSON(elm[key], `${path}.${key}`, depth +4);
        }
        console.info(" ");
        return;
      }
    } catch (err) { 
      console.info(`Error PrintJSON:`, err.message); 
    }
  }
  
}