<template>
  <section class="wa-full-height">

    <div class="columns is-centered is-gapless is-marginless mt2rem mb1rem is-centered is-multiline">
      <div class="column is-12-desktop is-12-tablet is-12-mobile m2rem">
        <b-button class="button is-primary" @click="goto('malta')">
          malta
        </b-button>
      </div>
      <div class="column is-12-desktop is-12-tablet is-12-mobile m2rem">
        <b-button class="button is-primary" @click="goto('spain')">
          spain
        </b-button>
      </div>
      <div class="column is-12-desktop is-12-tablet is-12-mobile m2rem">
        <b-button class="button is-primary" @click="goto('crypto')">
          crypto
        </b-button>
      </div>
    </div>
    
  </section>
</template>

<script>


import { mapState } from 'vuex';

export default {
  name: "Home",
  
  components: {
    // Post,
  },
    
  data() {
    return {}
  },
  
  computed: {
    ...mapState('firebase', ['firebase/auth','auth']),
  },
  watch: {
    auth(newAuthState) {
      // console.info("Firebase getAuthState Changed:",  newAuthState);
      this.FBuid = newAuthState.uid
    },
  },
  
  created(){ },
  
  mounted(){
    console.info("mounted home:...");
    // this.$store.dispatch("loader/showLoader", 1500, { root: true });
  },
  
  
  methods: {
    goto(routename){
      this.$router.push({name: routename});
    }
  }
};
</script>

<style lang="scss" scoped>

</style>