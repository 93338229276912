<template>
    <section>
      
      <div class="columns m1rem">
        
        <div class="column">
          <Card :cardTitle="$wl('setup_notif_title')" :cardIcon="'domain'" >
            <div>
               Browser notification permission: {{ notificationsState.hasPermission }}
               {{ notificationsState.token !== null ? `Token: ${notificationsState.token}` : `` }}             
            </div>
                           
            <ButtonsForm v-if="notificationsState.token === null" :confirmLabel="$wl('setup_notif_enable') " class="mt2rem" @ConfirmBtnClicked="EnableNotifications"></ButtonsForm>          
            <ButtonsForm v-if="notificationsState.token !== null" :confirmLabel="$wl('setup_notif_disable') " class="mt2rem" @ConfirmBtnClicked="DeleteNotifications"></ButtonsForm>
            <ButtonsForm v-if="notificationsState.token !== null" :confirmLabel="$wl('setup_notif_test') " class="mt2rem" @ConfirmBtnClicked="TestNotifications"></ButtonsForm>
            
          </Card>
        </div>
        
        
      </div>
      
    </section>
</template>

<script>


import Card from "@/components/public/general/card.vue";
import ButtonsForm from "@/components/public/general/buttonform.vue";
import { mapState } from 'vuex';

// import { isSupported, getToken, deleteToken } from "firebase/messaging";

export default {
    name: "Notifications",
    
    components: {
      Card,
      ButtonsForm,
    },
    
    data() {
      return {
        // viewMode      : 'setup',  //open//setup/active'
        // enabled       : false,
        // isSupported   : null,
        FBuid     : '',
        DeviceID  : '',
        platform  : 'web',
        frmdata   : {
                      token : '',
                    }
      }
    },
    
    computed: {
      ...mapState('notifications', ['notifications/notificationsState','notificationsState']),
      ...mapState('firebase', ['firebase/auth','auth']),
    },
    watch: {
      notificationsState(newState) {
        console.info("notificationsState Changed:",  newState);
      },
      auth(newAuthState) {
        this.FBuid = newAuthState.uid
        this.DeviceID = newAuthState.uid
        console.info(`this.DeviceID:`, this.DeviceID);
      },
    },
    
    mounted(){
      this.$store.dispatch('notifications/updatePermission');
      this.getFBuid();
    },
    
    methods: { 
      test() {
        console.info(`notificationsState:`, this.notificationsState);
      },
      async getFBuid(){
        let FBauthState = this.$store.getters['firebase/getAuthState'];
        if (!FBauthState) {
          this.FBuid = null
          return
        }
        this.FBuid = FBauthState.uid;
        this.DeviceID = this.FBuid;
        console.info(`this.DeviceID:`, this.DeviceID);
      },
      
      async EnableNotifications(){
        let token = await this.$store.dispatch('notifications/requestToken', { 
          deviceID: this.DeviceID,
          platform: this.platform
        });
        console.info(`token:`, token);
      },
      
      async DeleteNotifications(){
        let token = await this.$store.dispatch('notifications/removeToken', { 
          deviceID: this.DeviceID,
          platform: this.platform
        });
        console.info(`token:`, token);
      },
      
      async TestNotifications(){
        await this.$store.dispatch('notifications/test');
      },
            
    }
};
</script>

<style scoped>


    
</style>
