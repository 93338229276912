/*
 * TODO: dynamic language loading: https://www.youtube.com/watch?v=ddVm53j80vc
 * TODO: dynamic language loading: https://www.youtube.com/watch?v=ddVm53j80vc
 * TODO: dynamic language loading: https://www.youtube.com/watch?v=ddVm53j80vc
 * TODO: dynamic language loading: https://www.youtube.com/watch?v=ddVm53j80vc
 * TODO: dynamic language loading: https://www.youtube.com/watch?v=ddVm53j80vc
 */
const LanguageSets = require("./languages/web_language_sets");

const translate = (itemcode, language = null) => {
  if (itemcode === null || itemcode === false || itemcode === '') return itemcode;
  
  let translation  = LanguageSets.defaultset;    
  if (language !== null && typeof(LanguageSets[language]) !== "undefined") {
    translation = LanguageSets[language];
  }
  
  if (typeof(translation[itemcode]) !== "undefined") {
    return translation[itemcode];
  }
  
  return "unknown translation...";    
};

module.exports = translate;
