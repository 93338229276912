const SuperadminPage = () => import('@/views/superadmin/superadminpage')
import mqttlogoverview  from "@/views/superadmin/mqttlogoverview";
import mqttSpain        from "@/views/superadmin/mqtt_spain";
import mqttMalta        from "@/views/superadmin/mqtt_malta";
import crypto           from "@/views/superadmin/crypto";


 const Routes_SuperAdmin = [{
                              path: "/superadmin",
                              name: "SuperadminPage",
                              component: SuperadminPage,
                              // meta: { superadminNav: true },
                              meta: { navBar: true },
                            },{
                              path: "/mqttlogoverview",
                              name: "mqttlogoverview",
                              component: mqttlogoverview,
                              // meta: { superadminNav: true },
                              meta: { navBar: true },
                            },{
                              path: "/MQTTSpain",
                              name: "MQTTSpain",
                              component: mqttSpain,
                              // meta: { superadminNav: true },
                              meta: { navBar: true, hideFooter : true },
                            },{
                              path: "/MQTTMalta",
                              name: "MQTTMalta",
                              component: mqttMalta,
                              // meta: { superadminNav: true },
                              meta: { navBar: true, hideFooter : true },
                            },{
                              path: "/crypto",
                              name: "crypto",
                              component: crypto,
                              // meta: { superadminNav: true },
                              meta: { navBar: true, hideFooter : true },
                            }
];

export default Routes_SuperAdmin;