'use strict';
  /*
   * String FUNCTIONS
   */
// const { WaitTime }      = require('../../classes/general/general');
const { Array_removeDuplicates, isArray }      = require('./arrays');

const isFullTag = (TagHtml) => {
  let firstChar = TagHtml.substr(0,1);
  if (firstChar === ">" ||
      firstChar === " " ||
      firstChar === `\n` ||
      firstChar === `\r` ||
      firstChar == `\t`) { 
    return true;
  }
  
  return false;
}

module.exports = {
  
  GetRandomChars (length, characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz') {
    let result = '';
    for ( let i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  },
 
  
  StringFixedLengthAfter (ThaString, FixedLength, AddChar = "") {
    if (ThaString.length >= FixedLength) {
      return ThaString;
    }

    return ThaString + String(AddChar).repeat(FixedLength - ThaString.length);
  },
  
  
  StringFixedLengthBefore (ThaString, FixedLength, AddChar = "") {
    if (ThaString.length >= FixedLength) {
      return ThaString;
    }

    return String(AddChar).repeat(FixedLength - ThaString.length) + ThaString;
  },
  
  
  //First letter capitalize function
  CapitalizeFirstChar (ThaString) {
    let ThaStringPart = ThaString.split(" ");
  
    for (let i=0;i<ThaStringPart.length;i++){
      if (ThaStringPart[i].length> 0) {
        ThaStringPart[i] = ThaStringPart[i].substr(0,1).toUpperCase() + ThaStringPart[i].substr(1).toLowerCase();
      }
    }

    return ThaStringPart.join(" ");
  },
  
  
  CapStringOnMaxLength (ThaString, MaxLen, WholeWordcap = true) {   
    if (ThaString === null || typeof(ThaString) !== "string"){
      return "";
    }
  
    if (ThaString.length <= MaxLen){
      return ThaString;
    }
     
    let MaxRtnString = ThaString.substr(0, MaxLen-3); // -3 -->"..."
    if (WholeWordcap === true) {
      return MaxRtnString + "...";
    }
    
    let lastdot = MaxRtnString.lastIndexOf(".");
    let lastspace = MaxRtnString.lastIndexOf(" ");
    let lastcomma = MaxRtnString.lastIndexOf(",");
    let lastsign1 = MaxRtnString.lastIndexOf("-") + 1;
    let lastsign2 = MaxRtnString.lastIndexOf("_") + 1;

    let MaxCutPos = Math.max(0, lastdot, lastspace, lastcomma, lastsign1, lastsign2);

    if (MaxCutPos !== 0 && (MaxRtnString.length - MaxCutPos) < 25) { //max cut 25 char words otherwise ignore cut!
      return ThaString.substr(0, MaxCutPos) + "...";
    }
    
    return MaxRtnString + "...";
  },
   
   
  GetTextBetweenStrings (FullString, StartStr, EndStr, MaxStrLen = false, failValue = '', ignoreCase = false) {
    if (typeof(FullString) !== 'string' || typeof(StartStr) !== 'string'  || typeof(EndStr) !== 'string') {
      return failValue;
    }
    
    let SearchString = FullString;
    if (ignoreCase) {
      SearchString  = FullString.toLocaleLowerCase();
      StartStr      = StartStr.toLocaleLowerCase();
      EndStr        = EndStr.toLocaleLowerCase();
    }
    
     
    const StartPost = SearchString.indexOf(StartStr);
    if (StartPost <= -1) {
      return failValue;
    }
    
    let StrPart = SearchString.substr(StartPost + StartStr.length);
    const EndPost = StrPart.indexOf(EndStr);
    if (EndPost <= -1) {
      return failValue;
    }
    
    if (MaxStrLen !== false && EndPost > MaxStrLen) {
      return failValue;
    }
    
    return FullString.substr(StartPost + StartStr.length, EndPost);
  },
   
   
  RemoveTextBetween (FullString, StartStr, EndStr, MaxReplaceCount=false, ignoreCase = false, replaceWith = '') {
    let SearchStartPos  = 0;
    let Pos_start       = 0;
    let Pos_End         = 0;
    let replacecount    = 0;
    
    let Rtn             = [];
    
    let SearchString = FullString;
    if (ignoreCase) {
      SearchString  = FullString.toLocaleLowerCase();
      StartStr      = StartStr.toLocaleLowerCase();
      EndStr        = EndStr.toLocaleLowerCase();
    }
    
    while ((Pos_start = SearchString.indexOf(StartStr, SearchStartPos)) > -1 && Pos_End > -1){
      Pos_End = SearchString.indexOf(EndStr, Pos_start+1);
      
      if (Pos_End > -1){
        Rtn.push(FullString.substr(SearchStartPos, Pos_start - SearchStartPos) + replaceWith);
        SearchStartPos = Pos_End + EndStr.length;
        replacecount++;
      }
      
      
      if (MaxReplaceCount !== false && replacecount >= MaxReplaceCount || replacecount > 10000) {
        console.info("to many loops, aborting");
        break;
      }
    }
    
    Rtn.push(FullString.substr(SearchStartPos, FullString.length - SearchStartPos));
    // console.info("Rtn:", Rtn);
    
    return Rtn.join('');
  },
  
  
  getTextBetweenTags (HtmlText, TagCode, TagProp = "", MatchNr = 0, ReturnTagLineOnly = false) {
    //0. check if fulltext exists && larger is than 0
    if (typeof(HtmlText) !== "string" || HtmlText.trim() === "") {
      console.info(`No string input found`);
      return false;
    }
    
    //split text on request tag
    HtmlText = ` ` + HtmlText; //space added--> first [0] item can thereby always be removed!
    const TagSplit = HtmlText.split(`<${TagCode}`);
    
    //check if MatchNr exists
    if (MatchNr > TagSplit.length){
      console.info(`max Matchnr reached`);
      return false;
    }
    
    let Match_Counter = 0;
    let i = 1; //remove the first item (because of added space in explode function!
    
    while(i < TagSplit.length) {
      if (isFullTag(TagSplit[i]) !== true) {
        i++; continue; //(prevent half tag findings!)
      }
      
      //get opening tag end char position
      let TagEndPos =  TagSplit[i].indexOf(`>`);
      if (TagEndPos <= -1) {
        console.info(`Tag closing char missing, skipping`);
        i++; continue; //end tag not found, abort
      }
      
      const TagString = `<${TagCode}${TagSplit[i].substr(0,TagEndPos)}>`;
      if (TagProp !== '' && TagString.indexOf(TagProp) <= -1) {
        i++; continue; //TagString does not contain Required prop!
      }
      
      if (Match_Counter < MatchNr) {
        Match_Counter++;
        i++; continue;
      }
      
      if (ReturnTagLineOnly === true) {
        return TagString;
      }
      
      if (TagString.substr(-2) === "/>"){
        console.info(`Tag has no prop`);
        return ""; //noting in the tag!
      }
      
      
      let ReturnCode      = "";
      let SearchStartPos  = 0;   
      let SubTag_Counter  = 0;   
      do {
        //find the next closing tag
        TagEndPos =  TagSplit[i].indexOf(`</${TagCode}>`, SearchStartPos); //0. find closing tag
        
        if (TagEndPos <= -1) { //closing tag not found, go to the next part
          ReturnCode +=`<${TagCode}${TagSplit[i]}`;
          if (i<TagSplit.length && isFullTag(TagSplit[i+1]) === true) { //if the next tag is not a partialTag
            SubTag_Counter++;
          }
          SearchStartPos = 0;
          i++; //goto the next block!
          continue;
        }
        
        if (SubTag_Counter > 0){ //sub close tag found (child node)
          SubTag_Counter--;
          SearchStartPos = TagEndPos +1;
          continue;
        }
        
        ReturnCode += `<${TagCode}${TagSplit[i].substr(0, TagEndPos)}`; //Full block
        return ReturnCode.substr(TagString.length,ReturnCode.length - TagString.length);
        
      } while (i < TagSplit.length);
      
      console.info(`!!!!!!!!!!!!!!!!HARD END!!!!!!!!!!!!!!!!!!!!!!`);
      return ReturnCode;
    }
  },
  
  
  GetTextBetweenStrings_Array (ThaFullText_Array, GetText_Start, GetText_End, MaxLength = false) {
    if (isArray(ThaFullText_Array) === false){
      return false;
    }

    let RtnArray = [];
    for(let item of ThaFullText_Array) {
      RtnArray.push(module.exports.GetTextBetweenStrings(item, GetText_Start, GetText_End, MaxLength))
    }
    
    return RtnArray;
  },
  
  
  getText_Tot_FirstOccurence_FromRight (ThaString, Tot_Substring, RtnStringWhenFailed = false, CaseSensitive = false) {
    if (typeof(ThaString) !== "string") {
      return "";
    }
    
    //find the last occurence of the break-substring
    let findPos = -1;
    if (CaseSensitive === true) {
      findPos = ThaString.lastIndexOf(Tot_Substring);
    } else {
      findPos = ThaString.toLowerCase().lastIndexOf(Tot_Substring.toLowerCase());
    }
    
    if (findPos <= -1) {
      if (RtnStringWhenFailed === true) {
        return ThaString;
      }
      return "";
    }
    
    return ThaString.substr(0, findPos);
  },
  
  getText_Tot_FirstOccurence_FromLeft (ThaString, Tot_Substring, RtnStringWhenFailed = false, CaseSensitive = false) {
    if (typeof(ThaString) !== "string") {
      return "";
    }
    
    //find the last occurence of the break-substring
    let findPos = -1;
    if (CaseSensitive === true) {
      findPos = ThaString.indexOf(Tot_Substring);
    } else {
      findPos = ThaString.toLowerCase().indexOf(Tot_Substring.toLowerCase());
    }
    
    if (findPos <= -1) {
      if (RtnStringWhenFailed === true) {
        return ThaString;
      }
      return "";
    }
    
    return ThaString.substr(0, findPos);
  },
  
  getText_Vanaf_FirstOccurence_FromRight (ThaString, Tot_Substring, RtnStringWhenFailed = false, CaseSensitive = false) {
    if (typeof(ThaString) !== "string") {
      return "";
    }
    
    //find the last occurence of the break-substring
    let findPos = -1;
    if (CaseSensitive === true) {
      findPos = ThaString.lastIndexOf(Tot_Substring);
    } else {
      findPos = ThaString.toLowerCase().lastIndexOf(Tot_Substring.toLowerCase());
    }
    
    if (findPos <= -1) {
      if (RtnStringWhenFailed === true) {
        return ThaString;
      }
      return "";
    }
    
    return ThaString.substr(-(ThaString.length - findPos - Tot_Substring.length));
  },
  
  getText_Vanaf_FirstOccurence_FromLeft (ThaString, Tot_Substring, RtnStringWhenFailed = false, CaseSensitive = false) {
    if (typeof(ThaString) !== "string") {
      return "";
    }
    
    //find the last occurence of the break-substring
    let findPos = -1;
    if (CaseSensitive === true) {
      findPos = ThaString.indexOf(Tot_Substring);
    } else {
      findPos = ThaString.toLowerCase().indexOf(Tot_Substring.toLowerCase());
    }
    
    if (findPos <= -1) {
      if (RtnStringWhenFailed === true) {
        return ThaString;
      }
      return "";
    }
    
    return ThaString.substr(-(ThaString.length - findPos - Tot_Substring.length));
  },
  
  
  Get_Special_SplitArray (HtmlText, Split_String, Split_CapString = false, MustContainString = false, RtnEmpty_IfCapNotFound = true, CaseSensitive = false, RemoveCaseSensitiveness = true) {
    if (RemoveCaseSensitiveness === true){
      Split_String = Split_String.toLowerCase();
      if (typeof(Split_CapString) === "string" && Split_CapString !== ""){
        Split_CapString = Split_CapString.toLowerCase();
      }
      if (typeof(MustContainString) === "string" && MustContainString !== "") {
        MustContainString = MustContainString.toLowerCase();
      }
    }
    
    HtmlText = ' ' + HtmlText;
    let SplitParts = HtmlText.split(Split_String);
    SplitParts.shift(); //remove the first array item!
    
    let RtnArray = [];
    for(let i=0 ; i < SplitParts.length; i++) {
    
      if (typeof(Split_CapString) === "string" && Split_CapString !== ""){
        //cap splitpart if needed
        SplitParts[i] = module.exports.getText_Tot_FirstOccurence_FromLeft(SplitParts[i], Split_CapString, RtnEmpty_IfCapNotFound, CaseSensitive)  ;
      }

      if (typeof(SplitParts[i]) === "string" && SplitParts[i] !== ""){
        if (typeof(MustContainString) === "string" && MustContainString !== "") {
          if (SplitParts[i].indexOf(MustContainString) <= -1){
            SplitParts[i] = null;
          }
        }

        //Build clean RtnArray
        //$RtnArray = AddLinkToArray_IfNew($RtnArray, $SplitParts[$i], true);
        if (typeof(SplitParts[i]) === "string" && SplitParts[i].trim() !== "") {
          RtnArray.push(SplitParts[i]);
        }
      }
    }

    //Moved To the end, much faster!!!
    RtnArray = Array_removeDuplicates(RtnArray, CaseSensitive);

    if (RtnArray.length <= 0){
      return false;
    }
    
    return RtnArray;
  },
  
  
  GetALL_FromTillText_Array (TotalSearchText, FromString, Max_TillString, AddOnlyUnique = true, CaseSensitive = false) {
    if (typeof(TotalSearchText) !== "string" || TotalSearchText === "") {
      return false;
    }
    
    let SplitArray = []; 
    TotalSearchText = ' ' + TotalSearchText;  // add space for explode function !
    SplitArray = TotalSearchText.split(FromString);
    SplitArray.shift();  //remove the first array item!
    


    let RtnArray = [];
    for(let val of SplitArray){

      let FoundString = module.exports.getText_Tot_FirstOccurence_FromLeft(val, Max_TillString, false, CaseSensitive);
      if (FoundString) {
        RtnArray.push(FoundString);
      }
    }

    //Remove duplicated Moved To The end, much faster
    if (AddOnlyUnique === true){
      RtnArray = Array_removeDuplicates(RtnArray, CaseSensitive);
    }

    //echo sizeof($RtnArray);die();
    return RtnArray;
  },
  
  
  RemoveHTML_Comments (Html_Text) {
    return module.exports.RemoveTextBetween(Html_Text, "<!--", "-->");
  },
  
  RemoveJS_Comments (Html_Text) {
    let RtnText = Html_Text;
    RtnText = module.exports.RemoveTextBetween(RtnText, "/*", "*/");   /* ... */

    const Keep = {
       "://"  : `_ƒ####NOWREPLACEME$####ƒ1__` ,
       "\"//" : `_ƒ####NOWREPLACEME$####ƒ2__` ,
       "'//"  : `_ƒ####NOWREPLACEME$####ƒ3__` ,
    }
    
    for(let key in Keep) {
      RtnText = RtnText.split(key).join(Keep[key]); // keep http://, tcp:// etc  
    }

    //remove JS Commented lines
    RtnText = module.exports.RemoveTextBetween(RtnText, `//`, `\n`); //...

    for(let key in Keep) {
      RtnText = RtnText.split(Keep[key]).join(key); // keep http://, tcp:// etc  
    }

    return RtnText;
  },
  
  GetUrl_LinkPart (Url, FailVal = "") {
    if (typeof(Url) !== "string" || Url.trim() === "") {
      return FailVal;
    }
    
    const UrlParts = Url.split("?");

    if (UrlParts.length !== 2){
      return FailVal;
    } 
    
    return UrlParts[0];
  },
  
  GetUrl_GETPart (Url, FailVal = "") {
    if (typeof(Url) !== "string" || Url.trim() === "") {
      return FailVal;
    }
    
    const UrlParts = Url.split("?");

    if (UrlParts.length !== 2){
      return FailVal;
    } 
    
    return UrlParts[1];
  },
  
  AddHostText_IfnotEmpty(hostname, PartLink, skipWhenHTTPFound = true){
    if (typeof(PartLink) !== "string" || PartLink.trim() === "") {
      return "";
    } 
    
    
    if (PartLink.indexOf(hostname) === 0) { //do not add hostname if already in the link position 0!
      return PartLink;
    }
    
    if (skipWhenHTTPFound === true && (PartLink.toLowerCase().indexOf("http://") === 0 || PartLink.toLowerCase().indexOf("https://") === 0)) { //do not add hostname HTTP or HTTPS already found!
      return PartLink;
    } 
    
    return hostname + PartLink;    
  },
  
  Array_AddHostText_IfnotEmpty (hostname, Link_Array) {
    if (isArray(Link_Array) === false){
      return false;
    }
    
    for(let key in Link_Array) {
      Link_Array[key] = module.exports.AddHostText_IfnotEmpty(hostname, Link_Array[key])
    }

    return Link_Array;
  },
  
  ArrayStringReplace (ThaArray, ReplaceText ='', ReplaceWith = '') {
    if (isArray(ThaArray) !== true) {
      return false;
    }
    
    let RtnArray = [];
    for(let key in ThaArray) {
      RtnArray[key] = ThaArray[key].split(ReplaceText).join(ReplaceWith);
    }
    
    return RtnArray;
  },
  
  ArrayFilter (ThaArray, Mustcontain = false, MustNotContain = false, caseSensitive = true) {
    if (isArray(ThaArray) !== true) {
      return false;
    }
    
    let RtnArr = [];
    for(let val of ThaArray) {
      
      if (Mustcontain) {
        if (caseSensitive) {
          if (val.indexOf(Mustcontain) <= -1)  continue;
        } else {
          if (val.toLowerCase().indexOf(Mustcontain.toLowerCase()) <= -1)  continue;
        }
      } 
      
      if (MustNotContain) {
        if (caseSensitive) {
          if (val.indexOf(MustNotContain) > -1)  continue;
        } else {
          if (val.toLowerCase().indexOf(MustNotContain.toLowerCase()) > -1)  continue;
        }
      }
      
      RtnArr.push(val);
    }
    
    return RtnArr;
  },
  
  FilterString (ThaString, FilterRules = /[^0-9]+/g, ReplaceWith = '--') {
    //examples /[^0-9]+/g   -- /[^A-Z0-9]+/g  -- /[^A-z0-9.]+/g  -- /[^A-z0-9.+]+/g  -- /[^a-zA-Z0-9-\s]+/g 
    if (typeof(ThaString) !== "string" || ThaString === "") {
      return false;
    }
    
    return ThaString.replace(FilterRules, ReplaceWith)
  },
  
  isString (s) {
    return typeof s === 'string' || s instanceof String;
  },
    
}
