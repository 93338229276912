<template>
  <section class="wa-full-height">

    <div class="columns is-centered is-gapless is-marginless">
      <div class="column is-mobile is-narrow mt2rem mb2rem">
        <div class="has-text-grey is-size-2 is-size-4-mobile wa-underlined-title">Spain</div>
      </div>
    </div>
    
    <div class="columns is-centered is-gapless is-marginless">
      <!-- <Card class="column is-2" cardTitle="CS-PC2020" :cardIcon="'domain'" >
        <ButtonsForm confirmLabel="shutdown" class="mt2rem" @ConfirmBtnClicked="Shutdown"></ButtonsForm>  
        <ButtonsForm confirmLabel="sleep" class="mt2rem" @ConfirmBtnClicked="Shutdown"></ButtonsForm>  
        <ButtonsForm confirmLabel="reboot" class="mt2rem" @ConfirmBtnClicked="Shutdown"></ButtonsForm>  
      </Card> -->
      
      <Card class="column is-2" cardTitle="Cat Feeder" :cardIcon="'domain'" >
        <ButtonsForm confirmLabel="Give food" class="mt2rem" @ConfirmBtnClicked="GiveFood"></ButtonsForm>
      </Card>
      
      <!-- <Card class="column is-2" cardTitle="Cat Feeder 2" :cardIcon="'domain'" >
        <ButtonsForm confirmLabel="Give food 2" class="mt2rem" @ConfirmBtnClicked="GiveFood2"></ButtonsForm>
      </Card> -->
      
      <!-- <Card class="column is-2" cardTitle="Power" :cardIcon="'domain'" >
        <b-switch>Power1</b-switch>
        <b-switch>Power2</b-switch>
        <b-switch>Power3</b-switch>
      </Card> -->
      
      <!-- <Card class="column is-2" cardTitle="Sensors" :cardIcon="'domain'" >
        <b-switch>Front door</b-switch>
        <b-switch>Garage door</b-switch>
        <b-switch>Motion living</b-switch>
        <b-switch>Motion office</b-switch>
      </Card> -->
      
      <!-- <Card class="column is-2" cardTitle="Lights" :cardIcon="'domain'" >
        <b-switch>Office</b-switch>
        <b-switch>Bedroom1</b-switch>
        <b-switch>Bedroom2</b-switch>
        <b-switch>Gate1</b-switch>
        <b-switch>Stairs</b-switch>
      </Card> -->
      
      <!-- <Card class="column is-2" cardTitle="Wake up Alarm" :cardIcon="'domain'" >
        <b-switch>Status</b-switch>
        <ButtonsForm confirmLabel="test" class="mt2rem" @ConfirmBtnClicked="Shutdown"></ButtonsForm>
        
        <b-timepicker v-model="pickertime" inline hour-format="24" :increment-minutes="5" size="is-large"></b-timepicker>
        <ButtonsForm confirmLabel="SetAlarm" class="mt2rem" @ConfirmBtnClicked="Shutdown"></ButtonsForm>        
      </Card> -->
      
      
    </div>

  </section>
</template>

<script>

import Card from "@/components/public/general/card.vue";
import ButtonsForm from "@/components/public/general/buttonform.vue";

import axios_api from '@/plugins/axios_api';
import { 
  // MQTT_TOPIC_DATA,
  MQTT_GIVE_FOOD,
  MQTT_GIVE_FOOD2
} from '@/config/api-url-custom';


export default {
  name: "mqtt_spain",  
  components: {
    Card,
    ButtonsForm,
  },
    
  data() {
    return {
      // mqttdata: [],
      isLoading: true,
      pickertime: new Date(),
    }
  },
  
  created() {
    const curDate = new Date()
    this.pickertime.setTime(curDate.getTime() + 7*60*60*1000);
    console.info(`time:`, this.pickertime);
  },
  
  mounted(){
    // this.getTopicData();
    
  },
  
  methods: {
    // async getTopicData(){
    //   let rtn = await axios_api.get(`${MQTT_TOPIC_DATA}`);
    //   console.info(`rtn:`, rtn.data.payload);
    //   this.mqttdata = rtn.data.payload;
    //   this.isLoading = false;
    // },
    
    Shutdown(){
      console.info(`shutting down...`);
    },
    async GiveFood(){
      console.info(`Give Food...`);
      this.isLoading = true;
      let rtn = await axios_api.get(`${MQTT_GIVE_FOOD}`);
      console.info(`rtn:`, rtn.data.payload);
      this.isLoading = false;
    },
    async GiveFood2(){
      console.info(`Give Food 2...`);
      this.isLoading = true;
      let rtn = await axios_api.get(`${MQTT_GIVE_FOOD2}`);
      console.info(`rtn:`, rtn.data.payload);
      this.isLoading = false;
    }
  }
  
};
</script>

<style scoped>
  

  .modalcontainer{
    background-color: #FFF;
    padding: 1em;
    text-align: center;
  }
</style>