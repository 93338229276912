<template>
  <section class="wa-full-height">
    <h1>FlexBox example: <a href='https://www.youtube.com/watch?v=fYq5PXgSsbE' target='_blank'>Youtube</a></h1>
    <div class="columns is-multiline is-centered wrapper">
      <div class="column is-12 testitem">test1</div>
      <div class="column is-4 testitem">test2</div>
      <div class="column is-4 testitem">test3</div>
      <div class="column is-4 testitem">test4</div>
      <div class="column is-4 testitem">test5</div>
      <div class="column is-4 testitem">test6</div>
      <div class="column is-4 testitem">test7</div>
    </div>
  </section>
</template>

<script>

export default {
  name: "flexBoxTest",  
};
</script>


<style scoped>
  .testitem{
    background-color: red;
    margin: 1px;
    text-align: center;
    border: 1px solid black;
  }
  
  .wrapper{
    margin: 0;
    width: 100%;
    padding:25px;
    border: 1px solid green
  }

</style>