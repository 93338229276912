<template>
  <section>
    <div>Grid example</div>
    
    <div class="grid-container">
      <div class="grid-item grid-item-1">
        Item 1
        <!-- Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi fugiat debitis vero assumenda nobis inventore recusandae officia deserunt quia modi dolore exercitationem magnam enim, alias dignissimos consectetur autem cumque. Delectus consectetur dolorum, natus hic repellat dolorem esse commodi nihil iste dignissimos vero blanditiis sapiente inventore, repudiandae alias nesciunt consequatur voluptatem! -->
      </div>
      <div class="grid-item grid-item-2">
        Item 2
        <!-- Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nostrum, veniam. Error, eaque? Nesciunt est distinctio impedit, architecto consequatur ullam ipsum nisi nostrum excepturi necessitatibus? Dolores? -->
      </div>
      <div class="grid-item grid-item-3">Item 3</div>
      <div class="grid-item grid-item-4">Item 4</div>
      <div class="grid-item grid-item-5">Item 5</div>
    </div>
  </section>
</template>

<script>

// See video:
// https://www.youtube.com/watch?v=9zBsdzdE4sM

export default {
  name: "gridExample",  
};
</script>


<style scoped>
  .grid-container{
    display: grid;
    grid-template-columns: 200px 300px;
    /* grid-template-columns: 2fr 1fr; /* fr => fraction */
    grid-template-columns: repeat(4, 150px);
    
    /* grid-auto-rows: 150px;     */
    /* grid-template-rows: 200px; */
    grid-auto-rows: minmax(150px, auto);    
    
    /* grid-row-gap: 20px; */
    /* grid-column-gap: 10px; */    
    grid-gap: 20px;
    
    grid-template-areas: 
      "header header"
      "sidebar content"
      "sidebar content"
    ;
    
    /* background-color: red; */
    
  }
  
  .grid-item{
    position: relative;
    padding: 20px;
    padding-top: 50px;
    background-color: #379AD6;
    color: #222;
    border: 1px solid white;
  }
  
  .grid-item-1{
    grid-area: header;
  }
  .grid-item-2{
    grid-area: sidebar;
  }
  .grid-item-3{
    grid-area: content;
  }

</style>