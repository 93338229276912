<template>
  <section class="wa-full-height">

    <div class="columns is-centered is-gapless is-marginless">
      <div class="column is-mobile is-narrow mt2rem mb2rem">
        <div class="has-text-grey is-size-2 is-size-4-mobile wa-underlined-title">Malta</div>
      </div>
    </div>
    
    <div class="columns is-centered is-gapless is-marginless is-multiline">
      <!-- <Card class="column is-2" cardTitle="CS-PC2020" :cardIcon="'domain'" >
        <ButtonsForm confirmLabel="shutdown" class="mt2rem" @ConfirmBtnClicked="Shutdown"></ButtonsForm>  
        <ButtonsForm confirmLabel="sleep" class="mt2rem" @ConfirmBtnClicked="Shutdown"></ButtonsForm>  
        <ButtonsForm confirmLabel="reboot" class="mt2rem" @ConfirmBtnClicked="Shutdown"></ButtonsForm>  
      </Card> -->
      
      <Card class="column is-2 m2rem" cardTitle="Cat Feeder" :cardIcon="'domain'" >
        <ButtonsForm confirmLabel="Give food" class="mt2rem" @ConfirmBtnClicked="GiveFood"></ButtonsForm>
      </Card>
      
      <Card class="column is-2 m2rem" cardTitle="Malta Nuc" :cardIcon="'FIREWALL'" >
        <ButtonsForm confirmLabel="open Firewall" class="mt2rem" @ConfirmBtnClicked="nucOpenFirewall"></ButtonsForm>  
        <ButtonsForm confirmLabel="close Firewall" class="mt2rem" @ConfirmBtnClicked="nucCloseFirewall"></ButtonsForm>  
        <ButtonsForm confirmLabel="reboot" class="mt2rem" @ConfirmBtnClicked="nucReboot"></ButtonsForm>  
      </Card>
      
      <Card class="column is-2 m2rem" cardTitle="Malta MediaPC" :cardIcon="'FIREWALL'" >
        <h4>stat/sonoffp1/RESULT</h4>
        <ButtonsForm confirmLabel="start" class="mt2rem" @ConfirmBtnClicked="myMediaPcStart"></ButtonsForm>        
        <ButtonsForm confirmLabel="shutdown" class="mt2rem" @ConfirmBtnClicked="myMediaPcShutdown"></ButtonsForm>  
        <ButtonsForm confirmLabel="reboot" class="mt2rem" @ConfirmBtnClicked="myMediaPcReboot"></ButtonsForm>
        <ButtonsForm confirmLabel="checkCurrent" class="mt2rem" @ConfirmBtnClicked="myMediaCheckCurrent"></ButtonsForm>
        <h4>{{ myMediaPCCurrent  }}</h4>
        <!-- <ButtonsForm confirmLabel="reboot" class="mt2rem" @ConfirmBtnClicked="nucReboot"></ButtonsForm>   -->
      </Card>
      
      <Card class="column is-2 m2rem" cardTitle="Malta PC" :cardIcon="'FIREWALL'" >
        <h4>stat/sonoffp4/RESULT</h4>
        <ButtonsForm confirmLabel="start" class="mt2rem" @ConfirmBtnClicked="myMaltaPCStart"></ButtonsForm>        
        <ButtonsForm confirmLabel="shutdown" class="mt2rem" @ConfirmBtnClicked="myMaltaPCShutdown"></ButtonsForm>  
        <ButtonsForm confirmLabel="reboot" class="mt2rem" @ConfirmBtnClicked="myMaltaPCReboot"></ButtonsForm>
        <ButtonsForm confirmLabel="checkCurrent" class="mt2rem" @ConfirmBtnClicked="myMaltaPCCheckCurrent"></ButtonsForm>
        <h4>{{ myMaltaPCCurrent  }}</h4>
        <!-- <ButtonsForm confirmLabel="reboot" class="mt2rem" @ConfirmBtnClicked="nucReboot"></ButtonsForm>   -->
      </Card>
      
      
      
      <!-- <Card class="column is-2" cardTitle="Cat Feeder 2" :cardIcon="'domain'" >
        <ButtonsForm confirmLabel="Give food 2" class="mt2rem" @ConfirmBtnClicked="GiveFood2"></ButtonsForm>
      </Card> -->
      
      <!-- <Card class="column is-2" cardTitle="Power" :cardIcon="'domain'" >
        <b-switch>Power1</b-switch>
        <b-switch>Power2</b-switch>
        <b-switch>Power3</b-switch>
      </Card> -->
      
      <!-- <Card class="column is-2" cardTitle="Sensors" :cardIcon="'domain'" >
        <b-switch>Front door</b-switch>
        <b-switch>Garage door</b-switch>
        <b-switch>Motion living</b-switch>
        <b-switch>Motion office</b-switch>
      </Card> -->
      
      <!-- <Card class="column is-2" cardTitle="Lights" :cardIcon="'domain'" >
        <b-switch>Office</b-switch>
        <b-switch>Bedroom1</b-switch>
        <b-switch>Bedroom2</b-switch>
        <b-switch>Gate1</b-switch>
        <b-switch>Stairs</b-switch>
      </Card> -->
      
      <!-- <Card class="column is-2" cardTitle="Wake up Alarm" :cardIcon="'domain'" >
        <b-switch>Status</b-switch>
        <ButtonsForm confirmLabel="test" class="mt2rem" @ConfirmBtnClicked="Shutdown"></ButtonsForm>
        
        <b-timepicker v-model="pickertime" inline hour-format="24" :increment-minutes="5" size="is-large"></b-timepicker>
        <ButtonsForm confirmLabel="SetAlarm" class="mt2rem" @ConfirmBtnClicked="Shutdown"></ButtonsForm>        
      </Card> -->
      
      
    </div>

  </section>
</template>

<script>

import Card from "@/components/public/general/card.vue";
import ButtonsForm from "@/components/public/general/buttonform.vue";
import { DialogProgrammatic as Dialog } from 'buefy'

import axios_api from '@/plugins/axios_api';
import { 
  // MQTT_TOPIC_DATA,
  MQTT_GIVE_FOOD,
  // MQTT_GIVE_FOOD2,
  MQTT_MALTA_NUC_OPENFIREWALL,
  MQTT_MALTA_NUC_CLOSEFIREWALL,
  MQTT_MALTA_NUC_REBOOT,
  MQTT_MALTA_MYMEDIAPC_START,
  MQTT_MALTA_MYMEDIAPC_SHUTDOWN,
  MQTT_MALTA_MYMEDIAPC_REBOOT,
  MQTT_MALTA_MYMEDIAPC_CURRENT,
  MQTT_MALTA_MALTAPC_START,
  MQTT_MALTA_MALTAPC_SHUTDOWN,
  MQTT_MALTA_MALTAPC_REBOOT,
  MQTT_MALTA_MALTAPC_CURRENT,
  
} from '@/config/api-url-custom';


export default {
  name: "mqtt_spain",  
  components: {
    Card,
    ButtonsForm,
  },
    
  data() {
    return {
      // mqttdata: [],
      isLoading: true,
      pickertime: new Date(),
      myMediaPCCurrent: "-",
      myMaltaPCCurrent: "-"
    }
  },
  
  created() {
    const curDate = new Date()
    this.pickertime.setTime(curDate.getTime() + 7*60*60*1000);
    console.info(`time:`, this.pickertime);
  },
  
  mounted(){
    // this.getTopicData();
    setInterval(() => {
      this.myMediaCheckCurrent()
    }, 15*1000)
    this.myMediaCheckCurrent()
    
    setInterval(() => {
      this.myMaltaPCCheckCurrent()
    }, 16*1000)
    this.myMaltaPCCheckCurrent()
  },
  
  methods: {
    // async getTopicData(){
    //   let rtn = await axios_api.get(`${MQTT_TOPIC_DATA}`);
    //   console.info(`rtn:`, rtn.data.payload);
    //   this.mqttdata = rtn.data.payload;
    //   this.isLoading = false;
    // },
    
    Shutdown(){
      console.info(`shutting down...`);
    },
    async GiveFood(){
      console.info(`Give Food...`);
      this.isLoading = true;
      let rtn = await axios_api.get(`${MQTT_GIVE_FOOD}`);
      console.info(`rtn:`, rtn.data.payload);
      this.isLoading = false;
    },
    
    
    async nucOpenFirewall(){
      console.info(`nucOpenFirewall...`);
      this.isLoading = true;
      let rtn = await axios_api.get(`${MQTT_MALTA_NUC_OPENFIREWALL}`);
      console.info(`rtn:`, rtn.data.payload);
      this.isLoading = false;
    },
    async nucCloseFirewall(){
      console.info(`nucCloseFirewall...`);
      this.isLoading = true;
      let rtn = await axios_api.get(`${MQTT_MALTA_NUC_CLOSEFIREWALL}`);
      console.info(`rtn:`, rtn.data.payload);
      this.isLoading = false;
    },
    async nucReboot(){
      console.info(`nucReboot...`);
      this.isLoading = true;
      let rtn = await axios_api.get(`${MQTT_MALTA_NUC_REBOOT}`);
      console.info(`rtn:`, rtn.data.payload);
      this.isLoading = false;
    },
    
    
    async myMediaPcStart(){
      console.info(`myMediaPcStart...`);
      this.isLoading = true;
      let rtn = await axios_api.get(`${MQTT_MALTA_MYMEDIAPC_START}`);
      console.info(`rtn:`, rtn.data.payload);
      this.isLoading = false;
    },
    async myMediaPcShutdown(){
      Dialog.confirm({
          title: `MyMediaPC Shutdown`,
          message: `are you sure?`,
          onConfirm: this.myMediaPcShutdownConfirmed,
          cancelText: `NO`,
          confirmText: `Yes`,
      })
    },
    async myMediaPcShutdownConfirmed() {
      console.info(`myMediaPcShutdown...`);
      this.isLoading = true;
      let rtn = await axios_api.get(`${MQTT_MALTA_MYMEDIAPC_SHUTDOWN}`);
      console.info(`rtn:`, rtn.data.payload);
      this.isLoading = false;
    },
    async myMediaPcReboot(){
      Dialog.confirm({
          title: `MyMediaPC Reboot`,
          message: `are you sure?`,
          onConfirm: this.myMediaPcRebootConfirmed,
          cancelText: `NO`,
          confirmText: `Yes`,
      })
    },
    async myMediaPcRebootConfirmed(){
      console.info(`myMediaPcReboot...`);
      this.isLoading = true;
      let rtn = await axios_api.get(`${MQTT_MALTA_MYMEDIAPC_REBOOT}`);
      console.info(`rtn:`, rtn.data.payload);
      this.isLoading = false;
    },
    async myMediaCheckCurrent(){
      console.info(`myMediaCheckCurrent...`);
      this.isLoading = true;
      let rtn = await axios_api.get(`${MQTT_MALTA_MYMEDIAPC_CURRENT}`);
      console.info(`rtn:`, rtn.data.payload);
      this.myMediaPCCurrent = rtn.data.payload
      this.isLoading = false;
    },
    
    
    async myMaltaPCStart(){
      console.info(`myMaltaPCStart...`);
      this.isLoading = true;
      let rtn = await axios_api.get(`${MQTT_MALTA_MALTAPC_START}`);
      console.info(`rtn:`, rtn.data.payload);
      this.isLoading = false;
    },
    async myMaltaPCShutdown(){
      Dialog.confirm({
          title: `myMaltaPCShutdown Shutdown`,
          message: `are you sure?`,
          onConfirm: this.myMaltaPCShutdownConfirmed,
          cancelText: `NO`,
          confirmText: `Yes`,
      })
    },
    async myMaltaPCShutdownConfirmed() {
      console.info(`myMaltaPCShutdownConfirmed...`);
      this.isLoading = true;
      let rtn = await axios_api.get(`${MQTT_MALTA_MALTAPC_SHUTDOWN}`);
      console.info(`rtn:`, rtn.data.payload);
      this.isLoading = false;
    },
    async myMaltaPCReboot(){
      Dialog.confirm({
          title: `myMaltaPCReboot Reboot`,
          message: `are you sure?`,
          onConfirm: this.myMaltaPCRebootConfirmed,
          cancelText: `NO`,
          confirmText: `Yes`,
      })
    },
    async myMaltaPCRebootConfirmed(){
      console.info(`myMaltaPCRebootConfirmed...`);
      this.isLoading = true;
      let rtn = await axios_api.get(`${MQTT_MALTA_MALTAPC_REBOOT}`);
      console.info(`rtn:`, rtn.data.payload);
      this.isLoading = false;
    },
    async myMaltaPCCheckCurrent(){
      console.info(`myMaltaPCCheckCurrent...`);
      this.isLoading = true;
      let rtn = await axios_api.get(`${MQTT_MALTA_MALTAPC_CURRENT}`);
      console.info(`rtn:`, rtn.data.payload);
      this.myMaltaPCCurrent = rtn.data.payload
      this.isLoading = false;
    },
    // async GiveFood2(){
    //   console.info(`Give Food 2...`);
    //   this.isLoading = true;
    //   let rtn = await axios_api.get(`${MQTT_GIVE_FOOD2}`);
    //   console.info(`rtn:`, rtn.data.payload);
    //   this.isLoading = false;
    // }
  }
  
};
</script>

<style scoped>
  

  .modalcontainer{
    background-color: #FFF;
    padding: 1em;
    text-align: center;
  }
</style>