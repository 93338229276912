<template>
  <section class="wa-full-height">

    <div class="columns is-centered is-gapless is-marginless">
      <div class="column is-mobile is-narrow mt2rem mb2rem">
        <div class="has-text-grey is-size-2 is-size-4-mobile wa-underlined-title"> Name: {{ $route.name }}</div>
        <div class="has-text-grey is-size-2 is-size-4-mobile wa-underlined-title"> Path: {{ $route.path }}</div>
      </div>
    </div>
    
    <div class="columns is-centered is-gapless is-marginless mt2rem mb1rem is-centered is-multiline">
      <div class="column is-12-desktop is-12-tablet is-12-mobile m2rem">
        <b-button class="button is-primary" @click="goto('malta')">
          malta
        </b-button>
      </div>
      <div class="column is-12-desktop is-12-tablet is-12-mobile m2rem">
        <b-button class="button is-primary" @click="goto('spain')">
          spain
        </b-button>
      </div>
      <div class="column is-12-desktop is-12-tablet is-12-mobile m2rem">
        <b-button class="button is-primary" @click="goto('crypto')">
          crypto
        </b-button>
      </div>
    </div>


    <!-- <Footer></Footer> -->
  </section>
</template>

<script>

export default {
  name: "MainPage",
  
  data() {
    return {
      
    }
  },
  
  mounted(){
    // console.info("mounted:...");
  },
  
  methods: {
    goto(routename){
      this.$router.push({name: routename});
    }
  }
};
</script>

<style scoped>
</style>