
import UserPage         from "@/views/user/userpage.vue";
import Profile          from "@/views/user/profile.vue";


 const Routes_User = [{
                              path: "/userpage",
                              name: "userpage",
                              component: UserPage,
                              meta: { navBar: true }
                            }, {
                              path: "/profile",
                              name: "profile",
                              component: Profile,
                              meta: { navBar: true }
                            }
];

export default Routes_User;