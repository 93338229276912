<template>
  <section class="wa-full-height">

    <div class="columns is-centered is-gapless is-marginless">
      <div class="column is-mobile is-narrow mt2rem mb2rem">
        <div class="has-text-grey is-size-2 is-size-4-mobile wa-underlined-title"> MQTT_LOG_OVERVIEW</div>
      </div>
    </div>
    
    <b-field class="columns m1em">
        <b-switch v-model="showHidden" class="column is-offset-10 is-2">show hidden</b-switch>
    </b-field>
        
    <div class="columns m1em">
      <b-table
        class="column is-12"
        :data="mqttdata"
        :bordered="true"
        :striped="true"
        :narrowed="true"
        :loading="isLoading"
        :row-class="(row, index) => calcRowCSS(row)"
        :mobile-cards="true">
        
        
        <b-table-column field="id" label="id" width="10" numeric centered v-slot="props">
            {{ props.row.id }}
        </b-table-column>
        <b-table-column field="topic" label="topic" width="50" v-slot="props">
            {{ props.row.path }}
        </b-table-column>
        <b-table-column field="lastmessage" label="lastmsg" width="50" v-slot="props">
            <div @click="ShowModel(props.row.lastmsg)">
              {{ filterLastMsg(props.row.lastmsg) }}
            </div>
        </b-table-column>
        <b-table-column field="savedata" label="savedata" width="50" centered v-slot="props">
            <b-dropdown aria-role="list">
              <template #trigger="{ active }">
                  <b-button :label="props.row.savedata" :type="CalcSaveDataCSS(props.row.savedata)" :icon-right="active ? 'menu-up' : 'menu-down'" />
              </template>
              
              <b-dropdown-item aria-role="listitem" v-show="props.row.savedata !== 'Yes'" @click="SetState(props.row.path, 'Yes')">Yes</b-dropdown-item>
              <b-dropdown-item aria-role="listitem" v-show="props.row.savedata !== 'No'" @click="SetState(props.row.path, 'No')">No</b-dropdown-item>
              <b-dropdown-item aria-role="listitem" v-show="props.row.savedata !== 'Unknown'" @click="SetState(props.row.path, 'Unknown')">Unknown</b-dropdown-item>
            </b-dropdown>
        </b-table-column>
        <b-table-column field="Totalmsg" label="Totalmsg" width="50" numeric centered v-slot="props">
            {{ props.row.Totalmsg }}
            <div @click="ClearMessages(props.row.path)">X</div>
        </b-table-column>
        <b-table-column field="updated_at" label="updated_at" width="50" centered v-slot="props">
            {{ CalcDate(props.row.updated_at)}}
        </b-table-column>
        
        <b-table-column field="hide" label="hide" width="10" centered v-slot="props">
            {{props.row.hide === 1 ? 'X' : '' }}
        </b-table-column>
        
        
        <b-table-column label="action" date width="30" centered v-slot="props">
            <b-dropdown aria-role="list">
              <template #trigger="{ active }">
                  <b-button label="action" type="is-primary" :icon-right="active ? 'menu-up' : 'menu-down'" />
              </template>
              
              <b-dropdown-item aria-role="listitem" v-show="props.row.hide !== 1" @click="setHide(props.row.path, '1')">hide</b-dropdown-item>
              <b-dropdown-item aria-role="listitem" v-show="props.row.hide === 1" @click="setHide(props.row.path, '0')">un-hide</b-dropdown-item>
            </b-dropdown>
        </b-table-column>      

        <template #empty>
            <div class="has-text-centered">No records</div>
        </template>

      </b-table>
    </div>
    
    <b-modal v-model="showModel">
      <div class="modalcontainer">
        {{ modelData }}
      </div>
    </b-modal>

  </section>
</template>

<script>
import axios_api from '@/plugins/axios_api';
import { 
  MQTT_TOPIC_DATA, MQTT_TOPIC_SETSTATE, MQTT_TOPIC_CLEARMSG, MQTT_TOPIC_SETHIDE
} from '@/config/api-url-custom';

// eslint-disable-next-line no-unused-vars
import { DDMMYYYY_HHii } from '@/helpers/dates';
import { CapStringOnMaxLength } from '@/helpers/strings';


export default {
  name: "mqttlogoverview",  
  data() {
    return {
      mqttdata:[],
      isLoading: true,
      showHidden: false,
      showModel: false,
      modelData: ''
    }
  },
  
  mounted(){
    this.getTopicData();
  },
  
  methods: {
    async getTopicData(){
      let rtn = await axios_api.get(`${MQTT_TOPIC_DATA}`);
      // console.info(`rtn:`, rtn);
      this.mqttdata = rtn.data.payload;
      this.isLoading = false;
    },
    
    CalcDate(DateStr) {
      return DDMMYYYY_HHii(new Date(DateStr))
    },
    CalcSaveDataCSS(state){
      if (state === 'Yes') return 'is-primary'
      if (state === 'No') return 'is-warning'
      return 'is-light'
    },
    
    async SetState(topic, newState){
      await axios_api.post(`${MQTT_TOPIC_SETSTATE}`, { topic, state: newState });
      this.getTopicData();
    },
    
    async ClearMessages(topic){
      await axios_api.post(`${MQTT_TOPIC_CLEARMSG}`, { topic });
      this.getTopicData();
    },
    
    async setHide(topic, hide){
      await axios_api.post(`${MQTT_TOPIC_SETHIDE}`, { topic, hide });
      this.getTopicData();
    },
    
    filterLastMsg(msg){
      return CapStringOnMaxLength(msg, 50, true);
    },
    
    ShowModel(modelData){
      console.info(`showing modal:`, modelData);
      this.showModel = true;
      this.modelData = modelData
    },
    
    calcRowCSS(row){
      if (row.hide !== 1) return ''
      if (this.showHidden) return 'show-hidden-row'
      return 'is-hidden'
    },
  }
  
};
</script>

<style scoped>
  

  .modalcontainer{
    background-color: #FFF;
    padding: 1em;
    text-align: center;
  }
</style>